<template>
  <div class="thankyou-main">
    <div class="container">
      <div class="vx-row justify-center">
        <div class="vx-col lg:w-1/2 w-full">
          <div class="thank-you-content">
            <h1>Thank you</h1>
            <p>
              <span>Transaction Complete!</span> Thank you for your Business.
              You will receive a receipt via email. We strive to provide a high
              quality service to all of our customers. Below are some helpful
              hints:
            </p>
            <ul>
              <li>
                If you have not removed your tracking code, your site will
                automatically start tracking again within the next 5 minutes.
              </li>
              <li>
                All systems will begin working again without intervention as
                long as you have your tracking script installed.
              </li>
            </ul>
            <div class="flex justify-center mt-8 w-full float-left">
              <vs-button             
                type="filled"
                color="primary"
                @click="redirectToDashboard()"
                >{{ LabelConstant.buttonLabelClose }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-1016390547"></script>
<script>
export default {
  data() {
    return {
      companyName: "",
    };
  },
  mounted() {
    this.getLoggedInCompanyName();
    // conversion code
    if (this.$route.query.amount) {
      this.$vs.loading();
      setTimeout(() => {
        // conversion code
        let amount = this.$route.query.amount;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "purchase",
          orderID: this.companyName,
          conversionValue: amount,
        });
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());

        gtag("config", "AW-1016390547");
        //google analyst
        //this.$gtag.config("UA-3761402-36", { page_path: this.$route.path });
        this.$gtag.event("conversion", {
          send_to: "AW-1016390547/pLStCIyOq-gBEJPH0-QD",
          value: amount,
          currency: "USD",
          transaction_id: this.companyName,
        });
      }, 100);
      this.$vs.loading.close();
    }
  },
  methods: {
    //it will redirect to Dashboard when we click on close button
    redirectToDashboard() {
      this.transactionSuccess = false;
      this.$router.push({
        name: "Dashboard",
        params: { reload: true },
      });
    },
    getLoggedInCompanyName() {
      this.$vs.loading();
      this.axios
        .get("/ws/Organization/GetOrganization")
        .then((response) => {
          let data = response.data;
          this.companyName = data.Org_Name;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
  },
};
</script>

<style lang="scss">
.thankyou-main {
  align-content: flex-end;
  position: relative;
  min-height: 100vh;
  z-index: 1;
  padding: 24px 0px;
  display: flex;
  align-items: center;
  position: relative;
  background: #fff;
  .thank-you-content {
    box-shadow: 0px 5px 24px 1px rgba(0, 0 ,0, 0.1);
    float: left;
    width: 100%;
    border-radius: 1.5em;
    background-color: #ffffff;
    position: relative;
    padding: 32px 32px;
    margin-top: 24px;    
    h1 {
      width: 100%;
      font-size: 32px;
      font-weight: 700;      
      line-height: 46px;      
      float: left;
      position: relative;      
      line-height: 60px; 
      text-align: center;     
      margin-bottom: 16px;
      color: rgba(var(--vs-primary), 1);
    }
    p {
      float: left;
      width: 100%;
      text-align: center;
      color: #999;
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
      span {
        float: left;
        width: 100%;
        margin-bottom: 16px;
        letter-spacing: 1px;
        color: #2c2c2c;
        font-weight: 600;
        font-size: 20px;
      }
    }
    ul {
      float: left;
      width: 100%;      
      li {
        float: left;
        width: 100%;
        color: #626262;
        font-size: 14px;
        font-weight: 500;
        margin-top: 20px;
        padding-left: 24px;
        line-height: 24px;
        position: relative;
        &:after {
          position: absolute;
          left: 0;
          top: 8px;
          width: 9px;
          height: 9px;
          border-radius: 100%;
          content: "";
          background: linear-gradient(
            118deg,
            rgba(var(--vs-primary), 1),
            rgba(var(--vs-primary), 0.7)
          ) !important;
        }
      }
    }
  }
}

/* Responsive */
@media (max-width: 575px) {
  .thankyou-main .thank-you-content {
    padding: 24px 16px;
  }
  .thankyou-main .thank-you-content h1 {
    font-size: 30px;   
    margin: 0px 0px 8px;    
  }
  .thankyou-main .thank-you-content p span {
    font-size: 18px;
  }
}
</style>
